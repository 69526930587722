/* Add to existing App.css */

html {
  scroll-behavior: smooth;
}

main {
  margin-left: 2rem;
  margin-right: 2rem;
}

#header {
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  padding: 0;
}
#header p {
  margin: 0;
}

#about {
  margin-bottom: 2rem;
}

.publication {
  margin-bottom: 2rem;
}

.publication-title {
  font-weight: bold;
  margin: 0; /* Removed margin */
}

.publication-authors {
  font-style: italic;
  margin: 0;
}

.publication-details {
  font-size: 0.9em;
  margin: 0;
}

.publication-tag {
  background-color: #007bff;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-left: 10px;
}

.publication-tags {
  font-size: 0.8em;
  color: #666;
}

.bibtex-toggle {
  font-size: 0.8em;
  cursor: pointer;
}

.bibtex-content {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 10px;
  margin-top: 10px;
  font-size: 0.8em;
}

.bibtex-content pre {
  margin: 0;
  white-space: pre-wrap;
}

#experience h3 {
  margin-bottom: 0.2rem;
}

#experience p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#experience ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

#experience li {
  margin-bottom: 0.2rem;
}

.experience {
  margin-bottom: 2rem;
  border-radius: 5px;
}

.experience h3 {
  margin-top: 0;
}

.experience .experience-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem; /* Adjust as needed */
}

.experience .experience-info p {
  margin: 0; /* Reset margin */
}

.experience ul {
  margin-top: 10px;
  padding-left: 2.5rem;
  margin-bottom: 0; /* Adjust as needed */
}

#achievements h2 {
  margin-bottom: 0.1rem;
}

.details-toggle {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
}

.details-toggle:hover {
  background-color: #0056b3;
}

.project {
  margin-bottom: 0; /* Remove bottom margin */
  padding-bottom: 0.5rem;
}

.project h3 {
  margin-top: 0;
  margin-bottom: 0.5rem; /* Adjust as needed */
}

.project .project-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem; /* Adjust as needed */
}

.project .project-info p {
  margin: 0; /* Reset margin */
}

.project ul {
  margin-top: 10px;
  margin-bottom: 0; /* Adjust as needed */
}
